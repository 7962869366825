import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActiveDatePicker } from '../../components/common/ActiveDatePicker';
import { ACTIVE_STATES } from '../../components/common/Enums';
import { Routes } from '../../components/common/UrlUtils';
import { ValidationErrorMessage } from '../../components/common/VlidationErrorMessage';
import LayoutTemplateService from '../../services/LayoutTemplateService';
import ProductService from '../../services/ProductService';
import ProductTypeService from '../../services/ProductTypeService';
import ShapeService from '../../services/ShapeService';
import FontService from './../../services/FontService';
import { validateFormWithCustomMessages } from '../../utils/validation';
import Spinner from '../../components/common/Spinner';
import ServerError from '../../components/states/ServerError';
import { TextValuePairOptions } from '../../components/common/TextValuePairOptions';
import { appendIfExists, getItemFormData, readTextFileContents } from '../../utils/dataUtils';
import { AlertMessage } from '../../utils/AlertMessage';
import { getLoggedUserName } from '../../utils/authUtils';

const FIELD_SINGULAR = 'Product';

const ProductUpsertPage = () => {
  const history = useHistory();
  const { productId } = useParams();
  const [isFormValid, setIsFormValid] = useState(isEdit());
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [oldItem, setOldItem] = useState({});
  const [item, setItem] = useState({
    LastModified: {
      value: '',
    },
    LastModifiedBy: {
      value: getLoggedUserName(),
    },
    BackgroundImage: {
      value: '',
    },
    BackgroundImageUrl: {
      value: null,
    },
    Image: {
      value: null,
      errorMessage: '',
    },
    ImageUrl: {
      value: null,
    },
    OverlayImageUrl: {
      value: '',
    },
    Title: {
      value: '',
      errorMessage: '',
    },
    ProductTypeId: {
      value: '',
      errorMessage: '',
    },
    ShapeId: {
      value: '',
      errorMessage: '',
    },
    LayoutTemplates: {
      values: [],
      errorMessage: '',
    },
    DefaultLayoutId: {
      value: '',
      errorMessage: '',
    },
    DefaultFontId: {
      value: '',
      errorMessage: '',
    },
    Width: {
      value: '',
      errorMessage: '',
    },
    Height: {
      value: '',
      errorMessage: '',
    },
    Bleed: {
      value: '',
      errorMessage: '',
    },
    SectionPadding: {
      value: '',
      errorMessage: '',
    },
    FontSizeOverride: {
      value: '',
      errorMessage: '',
    },
    Rotation: {
      value: '',
      errorMessage: '',
    },
    IsPreviewImageOnTop: {
      value: false,
      errorMessage: '',
    },
    HasOutline: {
      value: false,
      errorMessage: '',
    },
    GenerateBiggerPreviewImage: {
      value: false,
      errorMessage: '',
    },
    IsMultiDimension: {
      value: false,
      errorMessage: '',
    },
    SideBleedTop: {
      value: '',
      errorMessage: '',
    },
    SideBleedRight: {
      value: '',
      errorMessage: '',
    },
    SideBleedLeft: {
      value: '',
      errorMessage: '',
    },
    SideBleedBottom: {
      value: '',
      errorMessage: '',
    },
    SideWidth: {
      value: '',
      errorMessage: '',
    },
    SideHeight: {
      value: '',
      errorMessage: '',
    },
    MinimalWidth: {
      value: '',
      errorMessage: '',
    },
    MinimalHeight: {
      value: '',
      errorMessage: '',
    },
    OptimalWidth: {
      value: '',
      errorMessage: '',
    },
    OptimalHeight: {
      value: '',
      errorMessage: '',
    },
    OverlayInputFile: {
      value: '',
      errorMessage: '',
    },
    ShowClipPathWarning: {
      value: false,
      errorMessage: '',
    },
    EnableEnhancement: {
      value: false,
      errorMessage: '',
    },
    BackgroundClassName: {
      value: '',
      errorMessage: '',
    },
    CakeBackgroundWidth: {
      value: '',
      errorMessage: '',
    },
    CakeBackgroundHeight: {
      value: '',
      errorMessage: '',
    },
    MultiCloneWidth: {
      value: '',
      errorMessage: '',
    },
    MultiCloneHeight: {
      value: '',
      errorMessage: '',
    },
    IsMultiClone: {
      value: false,
      errorMessage: '',
    },
    DisplayWatermark: {
      value: false,
      errorMessage: '',
    },
    WatermarkImageUrl: {
      value: '',
      errorMessage: '',
    },
    WatermarkImage: {
      value: '',
      errorMessage: '',
    },
    WatermarkPositionLeft: {
      value: '',
      errorMessage: '',
    },
    WatermarkPositionTop: {
      value: '',
      errorMessage: '',
    },
    RoundedCorners: {
      value: false,
      errorMessage: '',
    },
    CornerRadius: {
      value: '',
      errorMessage: '',
    },
    IsMaskedCake: {
      value: false,
      errorMessage: '',
    },
    CakeMaskPathDefinition: {
      value: '',
      errorMessage: '',
    },
    BleedMaskPathDefinition: {
      value: '',
      errorMessage: '',
    },
    Active: {
      value: ACTIVE_STATES.Yes,
      errorMessage: '',
    },
    ActiveStartDate: {
      value: '',
      errorMessage: '',
    },
    ActiveEndDate: {
      value: '',
      errorMessage: '',
    },
  });

  const [data, setData] = useState({
    productTypes: [],
    shapes: [],
    templates: [],
    fonts: [],
    rotationTypes: [],
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
    }

    async function fetchData() {
      if (productId) {
        const [result, productTypes, shapes, templates, fonts] = await Promise.all([
          ProductService.getById(productId),
          ProductTypeService.getAll(),
          ShapeService.getAll(),
          LayoutTemplateService.getAll(),
          FontService.getAll(),
        ]);

        prepareServerData(result, productTypes, shapes, templates, fonts);
      } else {
        const [productTypes, shapes, templates, fonts] = await Promise.all([
          ProductTypeService.getAll(),
          ShapeService.getAll(),
          LayoutTemplateService.getAll(),
          FontService.getAll(),
        ]);

        prepareServerData(null, productTypes, shapes, templates, fonts);
      }

      setIsLoading(false);
    }
  }, [item, data, isServerError, isFormDisabled]);

  function prepareServerData(product, productTypes, shapes, templates, fonts) {
    if (isEdit() && (!product || product.error)) {
      setIsServerError(true);
      return;
    }

    if (productTypes.error || shapes.error || templates.error || fonts.error) {
      setIsServerError(true);
      return;
    }
    const copyData = { ...data };
    copyData.productTypes = productTypes.data;
    copyData.shapes = shapes.data;
    copyData.templates = templates.data;
    copyData.fonts = fonts.data;
    copyData.rotationTypes = [
      { text: 'None', value: '0' },
      { text: '90 Right', value: '1' },
      { text: '180 Right', value: '2' },
      { text: '270 Right', value: '3' },
    ];

    setData(copyData);

    if (product) {
      const copyItem = { ...item };
      Object.keys(copyItem).forEach(key => {
        if (product.data[key] !== undefined && product.data[key] !== null) {
          if (Array.isArray(product.data[key])) {
            copyItem[key].values = product.data[key];
          } else {
            copyItem[key].value = product.data[key];
          }
        }
      });

      setItem(copyItem);
      setOldItem({
        BleedMaskPathDefinition: copyItem.BleedMaskPathDefinition.value,
        CakeMaskPathDefinition: copyItem.CakeMaskPathDefinition.value,
      });
    }
  }

  return (
    <>
      <h2 className='sub-header'>Product - {isEdit() ? 'Edit' : 'Add'} <a className="btn btn-default btn-xs" href={Routes.product.index}>
        <span aria-hidden="true" className="glyphicon glyphicon-arrow-left"></span> back</a>
      </h2>
      {AlertMessage.getContent(alertMessage)}
      {getFormContent()}
    </>
  );

  function isEdit() {
    return productId !== undefined;
  }

  // Event Handlers
  function onSubmitHandler(event) {
    event.preventDefault();

    if (!isFormValid) {
      setAlertMessage(AlertMessage.getInvalidFormMessage());
      return;
    }

    if (isEdit()) {
      updateProduct();
    } else {
      saveProduct();
    }
  }

  async function saveProduct() {
    setIsFormDisabled(true);
    const formData = getCreateFormData();
    const result = await ProductService.save(formData);
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, '', result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, ''));
    setTimeout(() => history.push(Routes.product.index), 1000);
  }

  async function updateProduct() {
    setIsFormDisabled(true);
    const formData = getItemFormData(item);
    const result = await ProductService.update(productId, formData);
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, '', result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, ''));
    setTimeout(() => history.push(Routes.product.index), 1000);
  }

  function getCreateFormData() {
    const formData = new FormData();
    Object.keys(item).forEach(key => {
      if (Array.isArray(item[key].values)) {
        item[key].values.forEach(value => appendIfExists(`${key}[]`, value, formData));
        return;
      }
      appendIfExists(key, item[key].value, formData);
    });

    return formData;
  }

  async function onInputChangeHandler(event) {
    const { result, valid } = validateFormWithCustomMessages(item, event);
    if (result.LayoutTemplates.values.length && result.DefaultLayoutId.value === '') {
      const template = data.templates.find(template => template.LayoutTemplateId.toString() === result.LayoutTemplates.values[0].toString());
      result.DefaultLayoutId = {
        ...result.DefaultLayoutId.value,
        value: result.LayoutTemplates.values[0],
        text: template.Title,
      };
    }

    if (event.target.name === 'BleedMaskPathDefinition') {
      if (result.BleedMaskPathDefinition.value !== null && typeof result.BleedMaskPathDefinition.value !== 'string') {
        result.BleedMaskPathDefinition.value = getSvgPathFromSvgString(await readTextFileContents(result.BleedMaskPathDefinition.value));
      }
    }

    if (event.target.name === 'CakeMaskPathDefinition') {
      if (result.CakeMaskPathDefinition.value !== null && typeof result.CakeMaskPathDefinition.value !== 'string') {
        result.CakeMaskPathDefinition.value = getSvgPathFromSvgString(await readTextFileContents(result.CakeMaskPathDefinition.value));
      }
    }

    if (event.target.type === 'file' && event.target.files && event.target.files.length) {
      const preview = event.target.getAttribute('preview');
      if (preview) {
        // Reset last modified because of the preview image
        result[preview].value = URL.createObjectURL(event.target.files[0]);
      }
    }

    setIsFormValid(valid);
    setItem(result);
  }

  function getSvgPathFromSvgString(svgString) {
    let parser = new DOMParser();
    let doc = parser.parseFromString(svgString, 'image/svg+xml');

    let pathElements = doc.getElementsByTagName('path');

    if (pathElements.length > 0) {
      return pathElements.item(0).getAttribute('d');
    }

    return '';
  }

  function onInvalidHandler(event) {
    // event.preventDefault();
  }

  function getFormContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <form onSubmit={onSubmitHandler} className="form-horizontal">
        <fieldset disabled={isFormDisabled}>
          {getLastModifyContent()}
          <div className="form-group">

            {getEditImageThumbnailContent()}

            <div className="col-sm-5">
              <input
                required={!isEdit()}
                data-val-name="Product Image"
                name="Image"
                preview="ImageUrl"
                type="file"
                accept="image/*"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
              <ValidationErrorMessage errorMessage={item.Image.errorMessage} />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-2">Product ID</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                required={true}
                name="Title"
                type="text"
                placeholder='Product ID'
                defaultValue={item.Title.value}
                data-val-required='The Product ID field is required.'
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
              <ValidationErrorMessage errorMessage={item.Title.errorMessage} />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-2">Type</label>
            <div className="col-md-5">
              <select
                required={true}
                data-val-name="Type"
                className="form-control input-sm"
                name="ProductTypeId"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}>
                {getProductTypeOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.ProductTypeId.errorMessage} />
            </div>
          </div>
          <div className="form-group">
            <label className="control-label col-md-2">Shape</label>
            <div className="col-md-5">
              <select
                required={true}
                data-val-name="Shape"
                className="form-control input-sm"
                name="ShapeId"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} >
                {getShapesOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.ShapeId.errorMessage} />
            </div>
          </div>

          {getTemplatesInputContent()}
          {getDefaultTemplateInputContent()}

          <div className="form-group">
            <label className="control-label col-md-2">Default Font</label>
            <div className="col-md-5">
              <select
                required={true}
                data-val-name="Default Font"
                className="form-control input-sm"
                name="DefaultFontId"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}>
                {getFontsOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.DefaultFontId.errorMessage} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Width</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                required={true}
                data-val-name="Width"
                name="Width"
                type="number"
                placeholder='Width'
                defaultValue={item.Width.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
              <ValidationErrorMessage errorMessage={item.Width.errorMessage} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Height</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                required={true}
                data-val-name="Height"
                name="Height"
                type="number"
                placeholder='Height'
                defaultValue={item.Height.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
              <ValidationErrorMessage errorMessage={item.Height.errorMessage} />
            </div>
          </div>

          {getBackgroundContent()}

          <div className="form-group">
            <label className="control-label col-md-2">Bleed</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                required={true}
                data-val-name="Bleed"
                name="Bleed"
                type="number"
                placeholder='Bleed'
                defaultValue={item.Bleed.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
              <ValidationErrorMessage errorMessage={item.Bleed.errorMessage} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Section padding</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                required={true}
                data-val-name="Section padding"
                name="SectionPadding"
                type="number"
                placeholder='Section Padding'
                defaultValue={item.SectionPadding.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
              <ValidationErrorMessage errorMessage={item.SectionPadding.errorMessage} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Font size override</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                name="FontSizeOverride"
                type="number"
                placeholder='Font Size Override'
                defaultValue={item.FontSizeOverride.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Bakery Image Rotation</label>
            <div className="col-md-5">
              <select
                className="form-control input-sm"
                name="Rotation"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} >
                {getRotationOptionsContent()}
              </select>
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-2">Is image on top of BG on Preview?</label>
            <div className="col-sm-5 form-control-checkbox-wrap">
              <input
                name="IsPreviewImageOnTop"
                type="checkbox"
                defaultChecked={item.IsPreviewImageOnTop.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-2">Image Has Outline?</label>
            <div className="col-sm-5 form-control-checkbox-wrap">
              <input
                name="HasOutline"
                type="checkbox"
                defaultChecked={item.HasOutline.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-2">Bigger Preview Image?</label>
            <div className="col-sm-5 form-control-checkbox-wrap">
              <input
                name="GenerateBiggerPreviewImage"
                type="checkbox"
                defaultChecked={item.GenerateBiggerPreviewImage.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-2">3D?</label>
            <div className="col-sm-5 form-control-checkbox-wrap">
              <input
                name="IsMultiDimension"
                type="checkbox"
                defaultChecked={item.IsMultiDimension.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Optimal Width</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                name="OptimalWidth"
                type="number"
                defaultValue={item.OptimalWidth.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Optimal Height</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                name="OptimalHeight"
                type="number"
                defaultValue={item.OptimalHeight.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Minimal Width</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                name="MinimalWidth"
                type="number"
                defaultValue={item.MinimalWidth.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Minimal Height</label>
            <div className="col-md-5">
              <input
                className="form-control text-box single-line"
                name="MinimalHeight"
                type="number"
                defaultValue={item.MinimalHeight.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          {getMultiDimensionSidesContent()}

          {getMultiCloneContent()}

          <div className="form-group">
            {
              item.OverlayImageUrl.value ?
                <div className="col-sm-2 text-right">
                  <img
                    className="img-responsive thumb thumb-hover"
                    src={item.OverlayImageUrl.value}
                    alt='overlay image thumbnail'
                    onError={(event) => event.target.removeAttribute('src')} />
                </div>
                :
                <label className="col-sm-2 control-label">Overlay Image</label>
            }
            <div className="col-sm-5">
              <input
                name="OverlayInputFile"
                preview="OverlayImageUrl"
                type="file"
                accept="image/*"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          {getWatermarkContent()}
          {getRadiusContent()}
          {getMaskedCakeContent()}

          <div className="form-group">
            <label className="control-label col-sm-2">Show a warning for browsers that do not support clip path?</label>
            <div className="col-sm-5 form-control-checkbox-wrap">
              <input
                name="ShowClipPathWarning"
                type="checkbox"
                defaultChecked={item.ShowClipPathWarning.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-sm-2">Should the output for this cake be enhanced by ImageMagick?</label>
            <div className="col-sm-5 form-control-checkbox-wrap">
              <input
                data-val-required="The Should the output for this cake be enhanced by ImageMagick? field is required."
                name="EnableEnhancement"
                type="checkbox"
                defaultChecked={item.EnableEnhancement.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </div>
          </div>

          <ActiveDatePicker
            key={'active_date_picker'}
            activeState={item.Active.value}
            activeStartDate={item.ActiveStartDate.value}
            activeEndDate={item.ActiveEndDate.value}
            onActiveStateChange={onActiveStateChangeHandler}
            onDateChange={onDateChangeHandler}
          />

          <div className="form-group">
            <div className="col-sm-offset-2 col-sm-10">
              <button className="btn btn-primary" type="submit">Save</button>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }

  // Edit
  function getLastModifyContent() {
    if (!isEdit()) {
      return null;
    }

    return (
      <div className="form-group">
        <label className="col-sm-2 control-label"><strong>Last modified</strong></label>
        <div className="col-sm-10">
          <p className="form-control-static">{item.LastModified.value} by {item.LastModifiedBy.value}</p>
          <input type="hidden" value={item.LastModified.value} />
          <input type="hidden" value={item.LastModifiedBy.value} />
        </div>
      </div>
    );
  }

  function getEditImageThumbnailContent() {
    if (productId === undefined) {
      return (<label className="col-sm-2 control-label">Upload</label>);
    }

    return (
      <div className="col-sm-2 text-right">
        <img
          className="img-responsive thumb thumb-hover"
          src={item.ImageUrl.value}
          alt='product image'
          onError={(event) => event.target.removeAttribute('src')} />
      </div>
    );
  }

  function onActiveStateChangeHandler(newState) {
    const copy = { ...item };
    copy.Active.value = newState;
    setItem(copy);
  }

  function onDateChangeHandler(date, field) {
    const copy = { ...item };
    copy[field].value = date;
    setItem(copy);
  }

  // TODO: Replace with component
  function getSelectTextValueComponent(items) {
    if (!items || !items.length) {
      return null;
    }

    return (
      <>
        {
          items.map(item => {
            return <option key={item.value} data-val={item.value} value={item.value}>{item.text}</option>;
          })
        }
      </>
    );
  }

  function getProductTypeOptionsContent() {
    return (
      <TextValuePairOptions
        items={data.productTypes.map(type => { return { text: type.Name, value: type.ProductTypeId }; })}
        selectedValues={item.ProductTypeId.value ? [item.ProductTypeId.value] : []}
      />
    );
    // return getSelectTextValueComponent(data.productTypes.map(type => { return { text: type.Name, value: type.ProductTypeId }; }));
  }

  function getShapesOptionsContent() {
    return (
      <TextValuePairOptions
        items={data.shapes.map(shape => { return { text: shape.Name, value: shape.ShapeId }; })}
        selectedValues={item.ShapeId.value ? [item.ShapeId.value] : []}
      />
    );
    // return getSelectTextValueComponent(data.shapes.map(shape => { return { text: shape.Name, value: shape.ShapeId }; }));
  }

  function getTemplatesInputContent() {
    if (!item.ProductTypeId.value || !item.ShapeId.value) {
      return null;
    }

    const templates = data.templates
      .filter(template =>
        template.ShapeId !== undefined && template.ShapeId.toString() === item.ShapeId.value.toString() &&
        template.ProductTypes.some(ProductTypeId => ProductTypeId.toString() === item.ProductTypeId.value.toString())
      )
      .map(t => { return { text: t.Title, value: t.LayoutTemplateId }; });
    if (!templates.length) {
      return null;
    }

    return (
      <div className="form-group">
        <label className="control-label col-md-2">Template ID(s)</label>
        <div className="col-md-5">
          <select
            required={true}
            data-val-name="Template ID(s)"
            className="form-control input-sm"
            name="LayoutTemplates"
            multiple={true}
            onInput={onInputChangeHandler}
            onBlur={onInputChangeHandler}
            onInvalid={onInvalidHandler} >
            {<TextValuePairOptions items={templates} selectedValues={item.LayoutTemplates.values} />}
          </select>
          <ValidationErrorMessage errorMessage={item.LayoutTemplates.errorMessage} />
        </div>
      </div>
    );
  }

  function getDefaultTemplateInputContent() {
    if (!item.LayoutTemplates.values.length) {
      return null;
    }

    const templates = [];
    item.LayoutTemplates.values.forEach(id => {
      const template = data.templates.find(template => template.LayoutTemplateId.toString() === id.toString());
      if (template) {
        templates.push({
          text: template.Title,
          value: template.LayoutTemplateId,
        });
      }
    });

    return (
      <div className="form-group">
        <label className="control-label col-md-2">Default Template</label>
        <div className="col-md-5">
          <select
            required={true}
            data-val-name="Default Template"
            className="form-control input-sm"
            name="DefaultLayoutId"
            onInput={onInputChangeHandler}
            onBlur={onInputChangeHandler}
            onInvalid={onInvalidHandler} >
            <TextValuePairOptions
              items={templates}
              selectedValues={item.DefaultLayoutId.value !== '' ? [item.DefaultLayoutId.value] : []}
            />
          </select>
          <ValidationErrorMessage errorMessage={item.DefaultLayoutId.errorMessage} />
        </div>
      </div>
    );
  }

  function getTemplatesOptionsContent() {
    if (!item.ProductTypeId.value || !item.ShapeId.value) {
      return null;
    }

    return getSelectTextValueComponent(data.templates
      .filter(t => t.ProductTypeId.toString() === item.ProductTypeId.value && t.ShapeId.toString() === item.ShapeId.value)
      .map(t => { return { text: t.value, value: t.key }; })
    );
  }

  function getFontsOptionsContent() {
    return (
      <TextValuePairOptions
        items={data.fonts.map(font => ({ text: font.Title, value: font.FontId }))}
        selectedValues={item.DefaultFontId.value !== '' ? [item.DefaultFontId.value] : []}
      />
    );
    // if (item.ProductTypeId === '' || item.ProductTypeId === null) {
    //   return null;
    // }

    // return getSelectTextValueComponent(data.fonts.map(font => { return { text: font.Title, value: font.FontId }; }));
  }

  function getRotationOptionsContent() {
    return getSelectTextValueComponent(data.rotationTypes);
  }

  // Form partials
  function getBackgroundContent() {
    return (
      <>
        <div className="form-group">
          {isEdit() ?
            <div class="col-sm-2 text-right">
              <img
                class="img-responsive thumb thumb-hover"
                src={item.BackgroundImageUrl.value}
                alt='background image'
                onError={(event) => event.target.removeAttribute('src')} />
              <input
                name="BackgroundImage"
                type="hidden"
                value={item.BackgroundImage.value} />
            </div>
            :
            null}
          <div className="col-sm-5">
            <input
              required={!isEdit()}
              data-val-name="Background Image"
              preview="BackgroundImageUrl"
              name="BackgroundImage"
              type="file"
              accept="image/*"
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
          <ValidationErrorMessage errorMessage={item.Image.errorMessage} />
        </div>

        <div className="form-group">
          <label className="control-label col-md-2">Background Class Name</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              required={true}
              data-val-name="Background Class Name"
              name="BackgroundClassName"
              type="text"
              defaultValue={item.BackgroundClassName.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
            <ValidationErrorMessage errorMessage={item.BackgroundClassName.errorMessage} />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-2">Cake Background Width</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              required={true}
              data-val-name="Cake Background Width"
              name="CakeBackgroundWidth"
              type="number"
              defaultValue={item.CakeBackgroundWidth.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
            <ValidationErrorMessage errorMessage={item.CakeBackgroundWidth.errorMessage} />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-2">Cake Background Height</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              required={true}
              data-val-name="Cake Background Height"
              name="CakeBackgroundHeight"
              type="number"
              defaultValue={item.CakeBackgroundHeight.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
            <ValidationErrorMessage errorMessage={item.CakeBackgroundHeight.errorMessage} />
          </div>
        </div>
      </>
    );
  }

  function getMultiDimensionSidesContent() {
    if (!item.IsMultiDimension.value) {
      return null;
    }

    return (
      <>
        <div className="form-group">
          <label className="control-label col-md-2">Side bleed top</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="SideBleedTop"
              type="number"
              defaultValue={item.SideBleedTop.value}
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-2">Side bleed right</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="SideBleedRight"
              type="number"
              defaultValue={item.SideBleedRight.value}
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-2">Side bleed bottom</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="SideBleedBottom"
              type="number"
              defaultValue={item.SideBleedBottom.value}
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-2">Side bleed left</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="SideBleedLeft"
              type="number"
              defaultValue={item.SideBleedLeft.value}
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-2">Side width</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="SideWidth"
              type="number"
              defaultValue={item.SideWidth.value}
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label col-md-2">Side height</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="SideHeight"
              type="number"
              defaultValue={item.SideHeight.value}
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
      </>
    );
  }

  function getMultiCloneContent() {
    const innerHtml = item.IsMultiClone.value ?
      <>
        <div className="form-group">
          <label className="control-label col-md-2">Multi Clone Width</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="MultiCloneWidth"
              type="number"
              defaultValue={item.MultiCloneWidth.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
            <ValidationErrorMessage errorMessage={item.MultiCloneWidth.errorMessage} />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-2">Multi Clone Height</label>
          <div className="col-md-5">
            <input
              className="form-control text-box single-line"
              name="MultiCloneHeight"
              type="number"
              defaultValue={item.MultiCloneHeight.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
            <ValidationErrorMessage errorMessage={item.MultiCloneHeight.errorMessage} />
          </div>
        </div>
      </>
      : null;
    return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-2">Is Multi Clone</label>
          <div className="col-sm-5 form-control-checkbox-wrap">
            <input
              name="IsMultiClone"
              type="checkbox"
              defaultChecked={item.IsMultiClone.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        {innerHtml}
      </>
    );
  }

  function getWatermarkContent() {
    return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-2">Display Watermark</label>
          <div className="col-sm-5 form-control-checkbox-wrap">
            <input
              name="DisplayWatermark"
              type="checkbox"
              defaultChecked={item.DisplayWatermark.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>

        <div class="form-group">
          {
            item.DisplayWatermark.value ?
              <div class="col-sm-2 text-right">
                <img
                  class="img-responsive thumb thumb-hover"
                  src={item.WatermarkImageUrl.value}
                  alt='watermark image'
                  onError={(event) => event.target.removeAttribute('src')}
                />
              </div>
              :
              <label class="col-sm-2 control-label" for="WatermarkImage">Watermark Image</label>
          }
          <div class="col-sm-10">
            <span class="left">
              <input
                name="WatermarkImage"
                preview="WatermarkImageUrl"
                type="file"
                accept="image/*"
                onChange={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler} />
            </span>
            <span>(Watermark Image)</span>
          </div>
        </div>

        {
          item.DisplayWatermark.value ?
            <>
              <div className="form-group">
                <label className="control-label col-md-2">Watermark Position Left</label>
                <div className="col-md-5">
                  <input
                    className="form-control text-box single-line"
                    name="WatermarkPositionLeft"
                    type="number"
                    defaultValue={item.WatermarkPositionLeft.value}
                    onChange={onInputChangeHandler}
                    onBlur={onInputChangeHandler}
                    onInvalid={onInvalidHandler} />
                  <ValidationErrorMessage errorMessage={item.WatermarkPositionLeft.errorMessage} />
                </div>
              </div>
              <div className="form-group">
                <label className="control-label col-md-2">Watermark Position Top</label>
                <div className="col-md-5">
                  <input
                    className="form-control text-box single-line"
                    name="WatermarkPositionTop"
                    type="number"
                    defaultValue={item.WatermarkPositionTop.value}
                    onChange={onInputChangeHandler}
                    onBlur={onInputChangeHandler}
                    onInvalid={onInvalidHandler} />
                  <ValidationErrorMessage errorMessage={item.WatermarkPositionTop.errorMessage} />
                </div>
              </div>
            </>
            :
            null
        }
      </>
    );
  }

  function getRadiusContent() {
    return (
      <>
        <div className="form-group">
          <label className="control-label col-sm-2">Rounded corners?</label>
          <div className="col-sm-5 form-control-checkbox-wrap">
            <input
              name="RoundedCorners"
              type="checkbox"
              defaultChecked={item.RoundedCorners.value}
              onChange={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler} />
          </div>
        </div>
        {
          item.RoundedCorners.value ?
            <>
              <div className="form-group">
                <label className="control-label col-md-2">Corner radius</label>
                <div className="col-md-5">
                  <input
                    className="form-control text-box single-line"
                    required={true}
                    data-val-name="Corner radius"
                    name="CornerRadius"
                    type="number"
                    defaultValue={item.CornerRadius.value}
                    onChange={onInputChangeHandler}
                    onBlur={onInputChangeHandler}
                    onInvalid={onInvalidHandler} />
                  <ValidationErrorMessage errorMessage={item.CornerRadius.errorMessage} />
                </div>
              </div>
            </>
            : null
        }
      </>
    );
  }

  function getMaskedCakeContent() {
    const isMaskedCakeInput = (
      <div className="form-group">
        <label className="control-label col-sm-2">IsMaskedCake</label>
        <div className="col-sm-5 form-control-checkbox-wrap">
          <input
            data-val-required="The IsMaskedCake field is required."
            name="IsMaskedCake"
            type="checkbox"
            defaultChecked={item.IsMaskedCake.value}
            onChange={onInputChangeHandler}
            onBlur={onInputChangeHandler}
            onInvalid={onInvalidHandler} />
        </div>
      </div>
    );

    if (!item.IsMaskedCake.value) {
      return isMaskedCakeInput;
    }

    return (
      <>
        {isMaskedCakeInput}
        <div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Cake Mask (outer) (SVG only)</label>
            <div className="col-sm-10">
              <span className="left">
                <input
                  name="CakeMaskPathDefinition"
                  type="file"
                  accept="image/*"
                  data-val-name="Cake Mask File"
                  onChange={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler} />
              </span>
              {
                oldItem.CakeMaskPathDefinition ?
                  <span>(Already exists)</span>
                  :
                  <span>(Not exists)</span>
              }
            </div>
          </div>
          <div className="form-group">
            <label className="col-sm-2 control-label">Bleed Mask (inner) (SVG only)</label>
            <div className="col-sm-10">
              <span className="left">
                <input name="BleedMaskPathDefinition"
                  type="file"
                  accept="image/*"
                  data-val-name="Bleed Mask File"
                  onChange={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler} />
              </span>
              {
                oldItem.BleedMaskPathDefinition ?
                  <span>(Already exists)</span>
                  :
                  <span>(Not exists)</span>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default ProductUpsertPage;
