import appConfig from '../config/app-config';
import { getAllFontsMock, getFontDetailMock } from './mocks/FontServiceMock';
import { DELETE, GET, POST, PUT } from './ApiService';

const baseUrl = `${appConfig.baseUri}/management/v1/Fonts`;
const fontFileDeletebaseUrl = '/api/editor/management/v1/FontFiles';
export default class FontService {

  static async getAll() {
    if (appConfig.isTest) {
      return { data: getAllFontsMock(), error: false };
    }

    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async getByProductType(productType) {
    return GET({
      url: `${baseUrl}/ByProductType/${productType}`,
      token: true,
    });
  }

  static async getById(id) {
    if (appConfig.isTest) {
      return { data: getFontDetailMock(), error: false };
    }

    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async update(id, request) {
    return PUT({
      url: `${baseUrl}/${id}`,
      token: true,
      body: request,
    });
  }

  static async deleteById(id) {
    return DELETE({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async uploadFontFiles(id, request) {
    return POST({
      url: `${baseUrl}/${id}/FontFile`,
      token: true,
      body: request,
    });
  }

  static async deleteFontFileById(id) {
    return DELETE({
      url: `${fontFileDeletebaseUrl}/${id}`,
      token: true,
    });
  }

  static async getFontFilesById(id) {
    return GET({
      url: `${baseUrl}/${id}/FontFiles`,
      token: true,
    });
  }

  static async deleteAllById(ids) {
    return POST({
      url: `${baseUrl}/BatchDelete`,
      token: true,
      body: ids,
    });
  }
}
