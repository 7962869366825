import { useEffect, useState } from 'react';
import Spinner from '../../components/common/Spinner';
import { Routes } from '../../components/common/UrlUtils';
import SortableTable from '../../components/SortableTable';
import ServerError from '../../components/states/ServerError';
import ProductService from '../../services/ProductService';
import ProductTypeService from '../../services/ProductTypeService';
import ShapeService from '../../services/ShapeService';
import { PageUtils } from '../PageUtils';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';
import { AlertMessage } from '../../utils/AlertMessage';
import { getPreviewImage } from '../../utils/dataUtils';

const ProductsPage = () => {
  const FIELD_ID = 'ProductId';
  const FIELD_SINGULAR = 'product';
  const FIELD_PLURAL = 'products';
  const PAGE_TITLE = 'Product management';
  const HEADERS = [
    { key: '', value: 'Thumb', sortable: false },
    { key: 'ProductId', value: 'Product ID', sortable: true },
    { key: 'Title', value: 'Type', sortable: true },
    { key: 'Shape', value: 'Shape', sortable: true },
    { key: 'Width', value: 'Width', sortable: true },
    { key: 'Height', value: 'Height', sortable: true },
    { key: 'LastModified', value: 'Last modified', sortable: true },
    { key: '', value: 'Active', sortable: false },
    { key: '', value: 'Options', sortable: false },
  ];
  const SEARCH_FIELDS = ['ProductId', 'Title', 'ProductTypeId', 'Shape', 'Width', 'Height', 'LastModified', 'Active'];

  const [sort, setSort] = useState({ by: 'ProductId', order: 'asc' });
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [data, setData] = useState({
    productTypes: [],
    shapeTypes: [],
  });

  const EDIT_ROUTE = Routes.product.edit;
  const CREATE_ROUTE = Routes.product.create;

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      const [result, productTypes, shapeTypes] = await Promise.all([
        ProductService.getAll(),
        ProductTypeService.getAll(),
        ShapeService.getAll()
      ]);
      if (result.error) {
        setIsServerError(true);
      } else {
        setItems(result.data.map(item => {
          return { ...item, ui: { checked: false } };
        }));
      }

      if (productTypes.error || shapeTypes.error) {
        setIsServerError(true);
        return;
      }

      let productTypesMap = {};
      let shapeTypesMap = {};

      productTypes.data.forEach(p => {
        productTypesMap[p.ProductTypeId] = p.Name;
      });

      shapeTypes.data.forEach(s => {
        shapeTypesMap[s.ShapeId] = s.Name;
      });

      setData(
        {
          productTypes: productTypesMap,
          shapeTypes: shapeTypesMap,
        }
      );
    }
  }, [items, isServerError, isLoading, alertMessage]);

  return (
    <>
      {PageUtils.getPageHeader(PAGE_TITLE, CREATE_ROUTE, FIELD_SINGULAR)}
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  // @Override
  function onSearchHandler(searchTerm) {
    setSearchTerm(searchTerm.trim().toLowerCase());
  }

  // @Override
  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <div className='table-responsive'>
        <SortableTable
          key={'sortable_table'}
          tableHeader={getHeaderContent()}
          tableFooter={getFooterContent()}
          items={getTableItems()}
          onSearchChanged={onSearchHandler}
        />
      </div>
    );
  }

  // @Override
  function getHeaderContent() {
    return PageUtils.getSortableHeaderContent(HEADERS, sort, isAllChecked(), checkAllHandler, changeSort);
  }

  // @Override
  function getFooterContent() {
    return PageUtils.getSortableFooterContent(HEADERS, isAllChecked(), sort, items, FIELD_PLURAL, checkAllHandler, changeSort, onMultiDeleteHandler);
  }

  // @Override
  function isAllChecked() {
    return PageUtils.isAllChecked(items, SEARCH_FIELDS, searchTerm, sort);
  }

  // @Override
  function getTableItems() {
    return PageUtils.getSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort)
      .map(item => {
        return {
          tableData: getItemContent(item),
          active: item.Active,
          key: item[FIELD_ID],
        };
      });
  }

  function getItemContent(item) {
    return (
      <>
        <td key={`${item[FIELD_ID]}_check`}>
          <input
            type='checkbox'
            checked={item.ui.checked}
            defaultChecked={item.ui.checked}
            onChange={event => onItemSelectChange(item, event)}
          />
        </td>
        <td key={`${item[FIELD_ID]}_thumb`}>
          {/* <span 
                aria-hidden='true' 
                id='@($'noImage{item.StickerId}')' 
                style='display:none' 
                className='glyphicon glyphicon-ban-circle thumb-notfound'>
              </span>
              <img 
                className='img-thumbnail thumb' 
                src='@item.ImageUrl' 
                onerror='this.onerror = null; this.style.display = 'none'; document.getElementById('@($'noImage{item.StickerId}')').style.display = '' ' /> */}
          <img
            className='img-thumbnail thumb'
            src={getPreviewImage(item.ImageUrl, item.LastModified)}
            onError={onImageLoadError}
            alt='product thumbnail' />
        </td>

        <td key={`${item[FIELD_ID]}_title`}>{item.Title}</td>
        <td key={`${item[FIELD_ID]}_product_type_id`}>{data.productTypes[item.ProductTypeId]}</td>
        <td key={`${item[FIELD_ID]}_shape`}>{data.shapeTypes[item.ShapeId]}</td>
        <td key={`${item[FIELD_ID]}_width`}>{item.Width}</td>
        <td key={`${item[FIELD_ID]}_height`}>{item.Height}</td>
        <td key={`${item[FIELD_ID]}_last_modified`}>{item.LastModified}</td>

        <td key={`${item[FIELD_ID]}_active`}>
          <span
            aria-hidden='true'
            className={`glyphicon glyphicon-${item.Active ? 'ok' : 'remove'}`}>
          </span>
          {item.Active ? ' Yes' : ' No'} {item.OriginalActiveState === 2 ? ' (limited)' : ''}
        </td>
        <td key={`${item[FIELD_ID]}_actions`}>
          <a className='btn btn-default btn-xs' href={`${EDIT_ROUTE}/${item[FIELD_ID]}`}>
            <span aria-hidden='true' className='glyphicon glyphicon-pencil'></span> edit
          </a>
          <a className='btn btn-default btn-xs'
            href="/#"
            onClick={event => onDeleteItemHandler(item, event)}>
            <span aria-hidden='true' className='glyphicon glyphicon-trash'></span> delete</a>
        </td>
      </>
    );
  }

  function changeSort(event) {
    event.preventDefault();
    const sortBy = event.target.getAttribute('sort-by');
    const order = sortBy === sort.by ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({
      by: sortBy,
      order,
    });
  }

  // @Override
  function onImageLoadError(event) {
    // TODO: onImageLoadError
  }

  // @Override
  function checkAllHandler(event) {
    setItems(PageUtils.checkAllSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort, event.target.checked));
  }

  // @Override
  async function onDeleteItemHandler(deletedItem, event) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FIELD_SINGULAR, deletedItem.Title)) {
      return;
    }

    const result = await ProductService.deleteById(deletedItem[FIELD_ID]);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FIELD_SINGULAR, deletedItem.Title, result));
    }

    setItems(items.filter(item => item[FIELD_ID] !== deletedItem[FIELD_ID]));
    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FIELD_SINGULAR, deletedItem.Title));
  }

  // @Override
  async function onMultiDeleteHandler(event) {
    event.preventDefault();

    const deletedItems = items.filter(item => item.ui.checked);
    if (!ConfirmationDialog.openDeleteItemsConfirmationMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL)) {
      return;
    }

    const restOfItems = items.filter(item => !item.ui.checked);
    const result = await ProductService.deleteAllById({ ProductIds: deletedItems.map(item => item[FIELD_ID]) });
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemsErrorMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL, result));
      return;
    }

    setItems(restOfItems);
    setAlertMessage(AlertMessage.getDeleteItemsSuccessMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL));
  }

  // @Override
  function onItemSelectChange(item, event) {
    setItems(PageUtils.toggleSelectedItem(items, FIELD_ID, item[FIELD_ID]));
  }
};

export default ProductsPage;
