import { useEffect, useState } from 'react';
import Spinner from '../../components/common/Spinner';
import { Routes } from '../../components/common/UrlUtils';
import SortableTable from '../../components/SortableTable';
import ServerError from '../../components/states/ServerError';
import LayoutTemplateService from '../../services/LayoutTemplateService';
import ProductTypeService from '../../services/ProductTypeService';
import ShapeService from '../../services/ShapeService';
import { AlertMessage } from '../../utils/AlertMessage';
import { PageUtils } from '../PageUtils';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';
import { getPreviewImage } from '../../utils/dataUtils';

const LayoutTemplatesPage = () => {
  const PAGE_TITLE = 'Layout Templates';
  const FIELD_ID = 'LayoutTemplateId';
  const HEADERS = [
    { key: 'no_key1', value: 'Thumb', sortable: false },
    { key: 'Title', value: 'Template ID', sortable: true },
    { key: 'ProductTypes', value: 'Type', sortable: false },
    { key: 'Shape', value: 'Shape', sortable: true },
    { key: 'LastModified', value: 'Last Modified', sortable: true },
    { key: 'Active', value: 'Active', sortable: true },
    { key: 'no_key2', value: 'Options', sortable: false },
  ];
  const SEARCH_FIELDS = ['Title', 'ProductTypes', 'Shape', 'LastModified', 'Active'];
  const ROUTE_EDIT = Routes.layout.edit;
  const ROUTE_CREATE = Routes.layout.create;

  const FIELD_SINGULAR = 'layout';
  const FIELD_PLURAL = 'layouts';

  const [isServerError, setIsServerError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ by: 'Title', order: 'asc' });
  const [items, setItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      const [result, productTypes, shapeTypes] = await Promise.all([
        LayoutTemplateService.getAll(),
        ProductTypeService.getAll(),
        ShapeService.getAll()
      ]);

      if (result.error) {
        setIsServerError(true);
        return;
      }

      let productTypesMap = {};
      let shapeTypesMap = {};

      productTypes.data.forEach(p => {
        productTypesMap[p.ProductTypeId] = p.Name;
      });

      shapeTypes.data.forEach(s => {
        shapeTypesMap[s.ShapeId] = s.Name;
      });

      result.data.forEach(item => {
        item.Shape = shapeTypesMap[item.ShapeId];
        item.ProductTypes = item.ProductTypes.map(productTypeId => productTypesMap[productTypeId]);
        item.ui = { checked: false };
      });

      setItems(result.data);
    }
  }, [items, sort, alertMessage, isServerError]);

  return (
    <>
      {PageUtils.getPageHeader(PAGE_TITLE, ROUTE_CREATE, FIELD_SINGULAR)}
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  // @Override
  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <div className="table-responsive">
        <SortableTable
          tableHeader={getHeaderContent()}
          tableFooter={getFooterContent()}
          items={getTableItems()}
          onSearchChanged={onSearchHandler}
          onOrderChange={onOrderChangedHandler}
        />
      </div>
    );
  }

  // @Override
  function onSearchHandler(searchTerm) {
    setSearchTerm(searchTerm.trim().toLowerCase());
  }

  // @Override
  async function onOrderChangedHandler(initIndex, newIndex, id) {
    const result = await LayoutTemplateService.updateOrder(id, newIndex);
    if (result.error) {
      setAlertMessage(AlertMessage.getReorderItemErrorMessage(FIELD_SINGULAR, '', newIndex, result));
      return;
    }

    setAlertMessage(AlertMessage.getReorderItemSuccessMessage(FIELD_SINGULAR, '', newIndex));
  }

  // @Override
  async function onMultiDeleteHandler(event) {
    event.preventDefault();

    const deletedItems = items.filter(item => item.ui.checked);
    if (!ConfirmationDialog.openDeleteItemsConfirmationMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL)) {
      return;
    }

    const deleteIds = deletedItems.map(item => item[FIELD_ID]);
    const result = await LayoutTemplateService.deleteAllById({ LayoutTemplates: deleteIds });
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemsErrorMessage(deleteIds.length, FIELD_SINGULAR, FIELD_PLURAL, result));
      return;
    }

    setItems(items.filter(item => !item.ui.checked));
    setAlertMessage(AlertMessage.getDeleteItemsSuccessMessage(deleteIds.length, FIELD_SINGULAR, FIELD_PLURAL));
  }

  // @Override
  function getTableItems() {
    return PageUtils.getSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort)
      .map(item => {
        return {
          tableData: getItemContent(item),
          active: item.Active,
          key: item[FIELD_ID],
        };
      });
  }

  // @Override
  function onItemSelectChange(item) {
    setItems(PageUtils.toggleSelectedItem(items, FIELD_ID, item[FIELD_ID]));
  }

  // @Override
  async function onDeleteItemHandler(event, eventItem) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FIELD_SINGULAR, eventItem.TemplateId)) {
      return;
    }

    const result = await LayoutTemplateService.deleteById(eventItem[FIELD_ID]);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FIELD_SINGULAR, eventItem.TemplateId, result));
    } else {
      setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FIELD_SINGULAR, eventItem.TemplateId));
      setItems(items.filter(item => item[FIELD_ID] !== eventItem[FIELD_ID]));
    }
  }

  // @Override
  function getHeaderContent() {
    return PageUtils.getSortableHeaderContent(HEADERS, sort, isAllChecked(), checkAllHandler, changeSort);
  }

  // @Override
  function getSortItem(headerKey) {
    if (headerKey === sort.by) {
      return sort.order;
    }
    return 'none';
  }

  // @Override
  function changeSort(event) {
    event.preventDefault();
    const sortBy = event.target.getAttribute('sort-by');
    const order = sortBy === sort.by ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({
      by: sortBy,
      order,
    });
  }

  // @Override
  function getFooterContent() {
    return PageUtils.getSortableFooterContent(HEADERS, isAllChecked(), sort, items, FIELD_PLURAL, checkAllHandler, changeSort, onMultiDeleteHandler);
  }

  // @Override
  function isAllChecked() {
    return PageUtils.isAllChecked(items, SEARCH_FIELDS, searchTerm, sort);
  }

  // @Override
  function checkAllHandler(event) {
    setItems(PageUtils.checkAllSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort, event.target.checked));
  }

  // @Override
  function onImageLoadError(event) {
  }

  // Custom
  function getItemContent(item) {
    return (
      <>
        <td key={`${item[FIELD_ID]}_check`}>
          <input
            type='checkbox'
            checked={item.ui.checked}
            onChange={event => onItemSelectChange(item, event)}
          />
        </td>
        <td key={`${item[FIELD_ID]}_thumb`}>
          <img
            className='img-thumbnail thumb'
            src={getPreviewImage(item.ImageUrl, item.LastModified)}
            onError={onImageLoadError} />
        </td>

        <td key={`${item[FIELD_ID]}_title`}>{item.Title}</td>
        <td key={`${item[FIELD_ID]}_type`}>{item.ProductTypes.join(', ')}</td>
        <td key={`${item[FIELD_ID]}_shape`}>{item.Shape}</td>
        <td key={`${item[FIELD_ID]}_last_modified`}>{item.LastModified}</td>

        <td key={`${item[FIELD_ID]}_active`}>
          <span
            aria-hidden='true'
            className={`glyphicon glyphicon-${item.Active ? 'ok' : 'remove'}`}>
          </span>
          {item.Active ? ' Yes' : ' No'} {item.OriginalActiveState === 2 ? ' (limited)' : ''}
        </td>
        <td key={`${item[FIELD_ID]}_actions`}>
          <a className='btn btn-default btn-xs' href={`${ROUTE_EDIT}/${item[FIELD_ID]}`}>
            <span aria-hidden='true' className='glyphicon glyphicon-pencil'></span> edit
          </a>
          <a className='btn btn-default btn-xs'
            href='#'
            onClick={event => onDeleteItemHandler(event, item)}>
            <span aria-hidden='true' className='glyphicon glyphicon-trash'></span> delete</a>
        </td>
      </>
    );
  }
};

export default LayoutTemplatesPage;
