import { DELETE, GET, POST, PUT } from './ApiService';
import appConfig from '../config/app-config';
import { getFiltersMock } from './mocks/FilterServiceMock';

const baseUrl = `${appConfig.baseUri}/management/v1/Filters`;
export default class FilterService {

  static async getAll() {
    if (appConfig.isTest) {
      return {data: getFiltersMock(), error: false };
    }
    return GET({
      url: baseUrl,
      token: true,
    });
  }

  static async getById(id) {
    return GET({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async save(request) {
    return POST({
      url: baseUrl,
      token: true,
      body: request,
    });
  }

  static async update(id, request) {
    return PUT({
      url: `${baseUrl}/${id}`,
      token: true,
      body: request,
    });
  }

  static async deleteById(id) {
    return DELETE({
      url: `${baseUrl}/${id}`,
      token: true,
    });
  }

  static async updateOrder(id, order) {
    return POST({
      url: `${baseUrl}/UpdateOrder`,
      token: true,
      body: { id, order },
    });
  }

  static async deleteAllById(ids) {
    return POST({
      url: `${baseUrl}/BatchDelete`,
      token: true,
      body: ids,
    });
  }
}
