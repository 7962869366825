import { useEffect, useState } from 'react';
import { Routes } from '../../components/common/UrlUtils';
import SortableTable from '../../components/SortableTable';
import BackgroundService from './../../services/BackgroundService';
import Spinner from './../../components/common/Spinner';
import ServerError from './../../components/states/ServerError';
import CategoryService from './../../services/CategoryService';
import { PageUtils } from '../PageUtils';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';
import { AlertMessage } from '../../utils/AlertMessage';
import { getPreviewImage } from '../../utils/dataUtils';

const BackgroundsPage = () => {
  const HEADERS = [
    { key: 'ImageUrl', value: 'Thumb', sortable: false },
    { key: 'Title', value: 'Title', sortable: true },
    { key: 'no_key1', value: 'Product Type(s)', sortable: false },
    { key: 'Size', value: 'Size', sortable: false },
    { key: 'no_key2', value: 'Categories', sortable: false },
    { key: 'LastModified', value: 'Last modified', sortable: true },
    { key: 'Active', value: 'Active', sortable: true },
    { key: 'no_key3', value: 'Options', sortable: false },
  ];
  const SEARCH_FIELDS = ['BackgroundId', 'Title', 'ProductTypes', 'Categories'];
  const FIELD_ID = 'BackgroundId';
  const FIELD_SINGULAR = 'background';
  const FIELD_PLURAL = 'backgrounds';

  const [isServerError, setIsServerError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ by: 'Title', order: 'asc' });
  const [items, setItems] = useState([]);
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      const [result, categories] = await Promise.all([
        BackgroundService.getAll(),
        CategoryService.getAll()
      ]);
      if (result.error) {
        setIsServerError(true);
      } else {
        let categoriesMap = {};
        categories.data.forEach(c => {
          categoriesMap[c.CategoryId] = c.Name;
        });

        result.data.forEach(item => {
          item.ui = { checked: false };
          item.Categories = item.Categories.map(c => categoriesMap[c]);
        });
        setItems(result.data);
      }
    }
  }, [items, sort, alertMessage, isServerError]);

  return (
    <>
      {PageUtils.getPageHeader(FIELD_PLURAL, Routes.background.create, FIELD_SINGULAR)}
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <div className="table-responsive js-index">
        <SortableTable
          tableHeader={getHeaderContent()}
          tableFooter={getFooterContent()}
          items={getTableItems()}
          onSearchChanged={onSearchHandler}
          onOrderChange={onOrderChangedHandler}
        />
      </div>
    );
  }

  // @Override
  function onSearchHandler(searchTerm) {
    setSearchTerm(searchTerm.trim().toLowerCase());
  }

  // @Override
  async function onOrderChangedHandler(initIndex, newIndex, backgroundId) {
    const result = await BackgroundService.updateOrder(backgroundId, newIndex);
    const background = items.find(item => item[FIELD_ID].toString() === backgroundId.toString());
    if (result.error) {
      setAlertMessage(AlertMessage.getReorderItemErrorMessage(FIELD_SINGULAR, background.Title, newIndex, result));
      return;
    }

    setAlertMessage(AlertMessage.getReorderItemSuccessMessage(FIELD_SINGULAR, background.Title, newIndex));
  }

  // @Override
  async function onMultiDeleteHandler(event) {
    event.preventDefault();
    const deletedItems = items.filter(item => item.ui.checked);

    if (!ConfirmationDialog.openDeleteItemsConfirmationMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL)) {
      return;
    }

    const result = await BackgroundService.deleteAllById(deletedItems.map(item => item.BackgroundId));
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemsErrorMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemsSuccessMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL));
    setItems(items.filter(item => !item.ui.checked));
  }

  // @Override
  function getTableItems() {
    return PageUtils.getSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort)
      .map(item => {
        return {
          tableData: getItemContent(item),
          active: item.Active,
          key: item[FIELD_ID],
        };
      });
  }

  function getItemContent(item) {
    return (
      <>
        <td key={item.backgroundId}>
          <input
            type="checkbox"
            checked={item.ui.checked}
            onChange={event => onItemSelectChange(item)}
          />
        </td>
        <td>
          <img
            className="img-thumbnail thumb"
            src={getPreviewImage(item.ImageUrl, item.LastModified)}
            onError={onImageLoadError} />
        </td>
        <td>{item.Title}</td>
        <td>{item.ProductTypeNames}</td>
        <td>{item.Size}</td>
        <td>{item.Categories.join(',')}</td>
        <td>{item.LastModified}</td>
        <td>
          <span
            aria-hidden="true"
            className={`glyphicon glyphicon-${item.Active ? 'ok' : 'remove'}`}>
          </span>
          {item.Active ? ' Yes' : ' No'} {item.OriginalActiveState === 2 ? ' (limited)' : ''}
        </td>
        <td>
          <a className="btn btn-default btn-xs" href={`${Routes.background.edit}/${item.BackgroundId}`}>
            <span aria-hidden="true" className="glyphicon glyphicon-pencil"></span> edit
          </a>
          <a className="btn btn-default btn-xs"
            href="/#"
            data-id={item.BackgroundId}
            data-title={item.Title}
            onClick={event => onDeleteItemHandler(event, item)}>
            <span aria-hidden="true" className="glyphicon glyphicon-trash"></span> delete</a>
        </td>
      </>
    );
  }

  function onItemSelectChange(item) {
    setItems(PageUtils.toggleSelectedItem(items, FIELD_ID, item.BackgroundId));
  }

  async function onDeleteItemHandler(event, eventItem) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FIELD_SINGULAR, eventItem.Title)) {
      return;
    }

    const result = await BackgroundService.deleteById(eventItem.BackgroundId);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FIELD_SINGULAR, eventItem.Title, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FIELD_SINGULAR, eventItem.Title));
    setItems(items.filter(item => item.BackgroundId !== eventItem.BackgroundId));
  }

  // @Override
  function getHeaderContent() {
    return PageUtils.getSortableHeaderContent(HEADERS, sort, isAllChecked(), checkAllHandler, changeSort);
  }

  function changeSort(event) {
    event.preventDefault();
    const sortBy = event.target.getAttribute('sort-by');
    const order = sortBy === sort.by ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({
      by: sortBy,
      order,
    });
  }

  // @Override
  function getFooterContent() {
    return PageUtils.getSortableFooterContent(HEADERS, isAllChecked(), sort, items, FIELD_PLURAL, checkAllHandler, changeSort, onMultiDeleteHandler);
  }

  // @Override
  function isAllChecked() {
    return PageUtils.isAllChecked(items, SEARCH_FIELDS, searchTerm, sort);
  }

  // @Override
  function checkAllHandler(event) {
    setItems(PageUtils.checkAllSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort, event.target.checked));
  }

  function onImageLoadError(event) {
  }
};

export default BackgroundsPage;
