import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ActiveDatePicker } from '../../components/common/ActiveDatePicker';
import { ACTIVE_STATES } from '../../components/common/Enums';
import { TextValuePairOptions } from '../../components/common/TextValuePairOptions';
import { Routes } from '../../components/common/UrlUtils';
import { ValidationErrorMessage } from '../../components/common/VlidationErrorMessage';
import { validateFormWithCustomMessages } from '../../utils/validation';
import ProductTypeService from './../../services/ProductTypeService';
import CategoryService from './../../services/CategoryService';
import BackgroundService from '../../services/BackgroundService';
import Spinner from '../../components/common/Spinner';
import ServerError from '../../components/states/ServerError';
import { appendIfExists } from '../../utils/dataUtils';
import { AlertMessage } from '../../utils/AlertMessage';

const FIELD_SINGULAR = 'background';
export const BackgroundUpsertPage = () => {
  const { backgroundId } = useParams();
  const history = useHistory();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [serverError, setServerError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(isEdit());
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [data, setData] = useState({
    productTypes: [],
    categoryTypes: [],
  });
  const [item, setItem] = useState({
    LastModified: {
      value: '',
    },
    LastModifiedBy: {
      value: '',
    },
    ImageUrl: {
      value: '',
      errorMessage: '',
    },
    EditorSpecificImageUrl: {
      value: '',
      errorMessage: '',
    },
    Image: {
      value: '',
      errorMessage: '',
    },
    EditorSpecific: {
      value: '',
      errorMessage: '',
    },
    Title: {
      value: '',
      errorMessage: '',
    },
    ProductTypes: {
      values: [],
      errorMessage: '',
    },
    Categories: {
      values: [],
      errorMessage: '',
    },
    Active: {
      value: ACTIVE_STATES.Yes,
      errorMessage: '',
    },
    ActiveStartDate: {
      value: '',
      errorMessage: '',
    },
    ActiveEndDate: {
      value: '',
      errorMessage: '',
    },
    BackgroundId: {
      value: '',
      errorMessage: '',
    }
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      if (isEdit()) {
        const [background, productTypes, categoryTypes] = await Promise.all(
          [
            BackgroundService.getById(backgroundId),
            ProductTypeService.getAll(),
            CategoryService.getAll(),
          ]
        );
        prepareData(background, productTypes, categoryTypes);
      } else {
        const [productTypes, categoryTypes] = await Promise.all(
          [
            ProductTypeService.getAll(),
            CategoryService.getAll(),
          ]
        );
        prepareData(null, productTypes, categoryTypes);
      }
    }
  }, [item, data, isFormDisabled, alertMessage, serverError]);

  function prepareData(background, productTypes, categoryTypes) {
    if ((background && background.error) || productTypes.error || categoryTypes.error) {
      setServerError(true);
      return;
    }

    if (background) {
      const copyItem = { ...item };
      Object.keys(copyItem).forEach(key => {
        if (Array.isArray(background.data[key])) {
          copyItem[key].values = background.data[key];
        } else {
          copyItem[key].value = background.data[key];
        }
      });
      setItem(copyItem);
    }

    setData(
      {
        productTypes: productTypes.data.map(p => { return { value: p.ProductTypeId, text: p.Name }; }),
        categoryTypes: categoryTypes.data.map(c => { return { value: c.CategoryId, text: c.Name }; }),
      }
    );
  }

  return (
    <>
      <h2 className='sub-header'>Backgrounds - {isEdit() ? 'Edit' : 'Add'} <a className="btn btn-default btn-xs" href={Routes.background.index}>
        <span aria-hidden="true" className="glyphicon glyphicon-arrow-left"></span> back</a>
      </h2>
      {AlertMessage.getContent(alertMessage)}
      {getFormContent()}
    </>
  );

  function isEdit() {
    return backgroundId !== undefined;
  }

  function getFormContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (serverError) {
      return (<ServerError />);
    }

    return (
      <form className="form-horizontal" onSubmit={onSubmitForm}>
        <fieldset disabled={isFormDisabled}>

          {getLastModifiedContent()}
          {getUploadFilesContent()}

          <div className="form-group">
            <label className="control-label col-md-2">Title</label>
            <div className="col-md-5">
              <input
                type="text"
                className="form-control"
                required={true}
                data-val-required="The Title field is required."
                name="Title"
                defaultValue={item.Title.value}
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}
              />
              <ValidationErrorMessage errorMessage={item.Title.errorMessage} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Product type(s)</label>
            <div className="col-md-5">
              <select
                className="form-control input-sm"
                required={true}
                data-val-required="The ProductTypes field is required."
                multiple={true}
                name="ProductTypes"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}>
                {getProductTypeOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.ProductTypes.errorMessage} />
            </div>
          </div>

          <div className="form-group">
            <label className="control-label col-md-2">Category</label>
            <div className="col-md-5">
              <select
                className="form-control input-sm"
                required={true}
                data-val-required="The Categories field is required."
                multiple={true}
                name="Categories"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}>
                {getCategoryOptionsContent()}
              </select>
              <ValidationErrorMessage errorMessage={item.Categories.errorMessage} />
            </div>
          </div>
          <ActiveDatePicker
            key={'active_date_picker'}
            activeState={item.Active.value}
            activeStartDate={item.ActiveStartDate.value}
            activeEndDate={item.ActiveEndDate.value}
            onActiveStateChange={onActiveStateChangeHandler}
            onDateChange={onDateChangeHandler}
          />

          <div className="form-group">
            <div className="col-sm-offset-2 col-sm-10">
              <button className="btn btn-primary" type="submit">Save</button>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }

  function getLastModifiedContent() {
    if (!isEdit()) {
      return null;
    }

    return (
      <div className="form-group">
        <label className="col-sm-2 control-label"><strong>Last modified</strong></label>
        <div className="col-sm-5">
          <p className="form-control-static">{item.LastModified.value} by {item.LastModifiedBy.value}</p>
        </div>
      </div>
    );
  };

  function getUploadFilesContent() {
    if (isEdit()) {
      return (
        <>
          <div className="form-group">
            <div className="col-sm-2 text-right">
              <img
                className="img-responsive thumb thumb-hover"
                src={item.ImageUrl.value} />
            </div>
            <div className="col-sm-5">
              <input
                type="file"
                name="Image"
                preview='ImageUrl'
                accept="image/*"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="col-sm-2 text-right">
              <img
                className="img-responsive thumb thumb-hover"
                src={item.EditorSpecificImageUrl.value}
                onError={(event) => event.target.removeAttribute('src')} />
            </div>
            <div className="col-sm-5">
              <input
                type="file"
                name="EditorSpecific"
                preview='EditorSpecificImageUrl'
                accept="image/*"
                onInput={onInputChangeHandler}
                onBlur={onInputChangeHandler}
                onInvalid={onInvalidHandler}
              />
              (specific to editor) {getEditorSpecificImageContent()}
            </div>
          </div>
        </>
      );
    }

    return (
      <>
        <div className="form-group">
          <label className="control-label col-md-2">Upload</label>
          <div className="col-md-10">
            <input
              className="btn btn-default"
              type="file"
              name="Image"
              accept="image/*"
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler}
            />
          </div>
        </div>

        <div className="form-group">
          <label className="control-label col-md-2">Upload</label>
          <div className="col-md-10">
            <input
              className="btn btn-default"
              type="file"
              name="EditorSpecific"
              accept="image/*"
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler}
            />
            (specific to editor) {getEditorSpecificImageContent()}
          </div>
        </div>
      </>
    );
  }

  function getEditorSpecificImageContent() {
    if (item.EditorSpecificImageUrl.value) {
      return (<a href="/#" onClick={(e) => removeEditorSpecificBackground(e, item.BackgroundId.value)}>Remove</a>);
    }

    return ('');
  }

  function getProductTypeOptionsContent() {
    return (<TextValuePairOptions items={data.productTypes} selectedValues={item.ProductTypes.values} />);
  }

  function getCategoryOptionsContent() {
    return (<TextValuePairOptions items={data.categoryTypes} selectedValues={item.Categories.values} />);

  }
  // Event Handlers
  function onInputChangeHandler(event) {
    const { result, valid } = validateFormWithCustomMessages(item, event);
    setIsFormValid(valid);

    if (event.target.type === 'file' && event.target.files && event.target.files.length) {
      const preview = event.target.getAttribute('preview');
      if (preview) {
        // Reset last modified because of the preview image
        result[preview].value = URL.createObjectURL(event.target.files[0]);
      }
    }

    setItem(result);
  }

  function onInvalidHandler(event) {
    // event.preventDefault();
  }

  // Callback Handlers
  function onActiveStateChangeHandler(newState) {
    const copy = { ...item };
    copy.Active.value = newState;
    setItem(copy);
  }

  function onDateChangeHandler(date, field) {
    const copy = { ...item };
    copy[field].value = date;
    setItem(copy);
  }

  function removeEditorSpecificBackground(event, backgroundId) {
    event.preventDefault();
    // removeEditorSpecificBackground
    // TODO
  }

  function onSubmitForm(event) {
    event.preventDefault();

    if (!isFormValid) {
      setAlertMessage(AlertMessage.getInvalidFormMessage());
      return;
    }

    if (isEdit()) {
      updateBackground();
    } else {
      saveBackground();
    }
  }

  // TODO: Remove size, extension, lastModified, etc.
  // it's calculated on the BE side
  async function updateBackground() {
    setIsFormDisabled(true);
    const result = await BackgroundService.update(item.BackgroundId.value, getFormData());
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, item.Title.value, result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, item.Title.value));
    setTimeout(() => history.push(Routes.background.index), 1000);
  }

  async function saveBackground() {
    setIsFormDisabled(true);
    const result = await BackgroundService.save(getFormData());
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(FIELD_SINGULAR, item.Title.value, result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(FIELD_SINGULAR, item.Title.value));
    setTimeout(() => history.push(Routes.background.index), 1000);
  }

  function getFormData() {
    const formData = new FormData();
    appendIfExists('Active', item.Active.value, formData);
    appendIfExists('ActiveStartDate', item.ActiveStartDate.value, formData);
    appendIfExists('ActiveEndDate', item.ActiveEndDate.value, formData);
    appendIfExists('Title', item.Title.value, formData);
    appendIfExists('Image', item.Image.value, formData);
    appendIfExists('EditorSpecificImage', item.EditorSpecific.value, formData);
    item.Categories.values.forEach(c => appendIfExists('CategoryIds[]', c, formData));
    item.ProductTypes.values.forEach(p => appendIfExists('ProductTypeIds[]', p, formData));
    return formData;
  }
};
