import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ActiveDatePicker } from '../../components/common/ActiveDatePicker';
import { ACTIVE_STATES } from '../../components/common/Enums';
import { TextValuePairOptions } from '../../components/common/TextValuePairOptions';
import { Routes } from '../../components/common/UrlUtils';
import { ValidationErrorMessage } from '../../components/common/VlidationErrorMessage';
import CategoryService from '../../services/CategoryService';
import FontService from '../../services/FontService';
import ProductTypeService from '../../services/ProductTypeService';
import { validateFormWithCustomMessages } from '../../utils/validation';
import Spinner from './../../components/common/Spinner';
import ServerError from './../../components/states/ServerError';
import { appendIfExists, getPreviewImage } from '../../utils/dataUtils';
import { AlertMessage } from '../../utils/AlertMessage';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';

const FONT_FILE_FIELD_SINGULAR = 'font file';
export const FontUpsertPage = () => {
  const history = useHistory();
  const { fontId } = useParams();
  const [isFormDisabled, setIsFormDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isServerError, setIsServerError] = useState(false);
  const [isFormValid, setIsFormValid] = useState(isEdit());
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [fontFiles, setFontFiles] = useState([]);
  const [data, setData] = useState({
    productTypes: [],
    categoryTypes: [],
  });
  const [item, setItem] = useState({
    LastModified: {
      value: '',
      errorMessage: '',
    },
    LastModifiedBy: {
      value: '',
      errorMessage: '',
    },
    Image: {
      value: '',
      errorMessage: '',
    },
    ImageUrl: {
      value: '',
      errorMessage: '',
    },
    Title: {
      value: '',
      errorMessage: '',
    },
    MinimumFontSize: {
      value: '',
      errorMessage: '',
    },
    MaximumFontSize: {
      value: '',
      errorMessage: '',
    },
    DefaultFontSize: {
      value: '',
      errorMessage: '',
    },
    CorrectionY: {
      value: '',
      errorMessage: '',
    },
    ProductTypeIds: {
      values: [],
      errorMessage: '',
    },
    CategoryIds: {
      values: [],
      errorMessage: '',
    },
    Active: {
      value: ACTIVE_STATES.Yes,
      errorMessage: '',
    },
    ActiveStartDate: {
      value: '',
      errorMessage: '',
    },
    ActiveEndDate: {
      value: '',
      errorMessage: '',
    },
    FontFiles: {
      values: [],
      errorMessage: '',
    },
  });

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      if (isEdit()) {
        const [font, productTypes, categories] = await Promise.all([
          FontService.getById(fontId),
          ProductTypeService.getAll(),
          CategoryService.getAll(),
        ]);
        prepareData(font, productTypes, categories);
      } else {
        const [productTypes, categories] = await Promise.all([
          ProductTypeService.getAll(),
          CategoryService.getAll(),
        ]);
        prepareData(null, productTypes, categories);
      }
    }

  }, [item, isFormDisabled, alertMessage, isServerError]);

  function prepareData(font, productTypes, categoryTypes) {
    if ((font && font.error) || productTypes.error || categoryTypes.error) {
      setIsServerError(true);
      return;
    }

    if (font) {
      const copyItem = { ...item };
      Object.keys(copyItem).forEach(key => {
        if (!font.data[key]) {
          return;
        }

        if (Array.isArray(font.data[key])) {
          copyItem[key].values = font.data[key] || [];
        } else {
          copyItem[key].value = font.data[key];
        }
      });
      setItem(copyItem);
    }

    setData(
      {
        productTypes: productTypes.data.map(p => { return { value: p.ProductTypeId, text: p.Name }; }),
        categoryTypes: categoryTypes.data.map(c => { return { value: c.CategoryId, text: c.Name }; }),
      }
    );
  }

  return (
    <>
      <h2>Fonts - {isEdit() ? 'Edit' : 'Add'} <a className="btn btn-default btn-xs" href={Routes.font.index}>
        <span aria-hidden="true" className="glyphicon glyphicon-arrow-left"></span> back</a>
      </h2>
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  function isEdit() {
    return fontId !== undefined;
  }

  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <>
        {getFormContent()}
        {getUploadFontsFormContent()}
      </>
    );
  }

  // Forms
  function getFormContent() {
    return (
      <form
        className="form-horizontal" onSubmit={onSubmitForm}>
        <fieldset disabled={isFormDisabled}>
          <div className="form-horizontal">
            <hr />
            {getLastModifiedContent()}

            {getUploadFileContent()}

            <div className="form-group">
              <label className="control-label col-md-2" for="Title">Title</label>
              <div className="col-md-10">
                <input
                  className="form-control text-box single-line"
                  name="Title"
                  type="text"
                  defaultValue={item.Title.value}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                />
                <ValidationErrorMessage errorMessage={item.Title.errorMessage} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Minimal font size</label>
              <div className="col-md-10">
                <input
                  className="form-control text-box single-line"
                  required={true}
                  data-val-required="Minimal font size field is required"
                  max="500"
                  min="1"
                  name="MinimumFontSize"
                  type="number"
                  defaultValue={item.MinimumFontSize.value}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                />
                <ValidationErrorMessage errorMessage={item.MinimumFontSize.errorMessage} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Maximal font size</label>
              <div className="col-md-10">
                <input
                  className="form-control text-box single-line"
                  required={true}
                  data-val-required="Maximal font size field is required"
                  max="500"
                  min="1"
                  name="MaximumFontSize"
                  type="number"
                  defaultValue={item.MaximumFontSize.value}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                />
                <ValidationErrorMessage errorMessage={item.MaximumFontSize.errorMessage} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Default font size</label>
              <div className="col-md-10">
                <input
                  className="form-control text-box single-line"
                  required={true}
                  data-val-required="Default font size field is required"
                  max="500"
                  min="1"
                  name="DefaultFontSize"
                  type="number"
                  defaultValue={item.DefaultFontSize.value}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                />
                <ValidationErrorMessage errorMessage={item.DefaultFontSize.errorMessage} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Correction Y per 100 font size</label>
              <div className="col-md-10">
                <input
                  className="form-control"
                  data-val-required="Correction Y per 100 font size field is required"
                  max="1000"
                  min="-1000"
                  name="CorrectionY"
                  step="0.1"
                  type="number"
                  defaultValue={item.CorrectionY.value}
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                />
                <ValidationErrorMessage errorMessage={item.CorrectionY.errorMessage} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Product type(s)</label>
              <div className="col-md-10">
                <select
                  className="form-control input-sm"
                  required={true}
                  data-val-required="Please select at least one product type"
                  multiple={true}
                  name="ProductTypeIds"
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                >
                  {getProductTypeOptionsContent()}
                </select>
                <ValidationErrorMessage errorMessage={item.ProductTypeIds.errorMessage} />
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2" for="CategoryIds">Category</label>
              <div className="col-md-10">
                <select
                  className="form-control input-sm"
                  required={true}
                  data-val-required="Please select at least one category type"
                  multiple={true}
                  name="CategoryIds"
                  onInput={onInputChangeHandler}
                  onBlur={onInputChangeHandler}
                  onInvalid={onInvalidHandler}
                >
                  {getCategoryOptionsContent()}
                </select>
                <ValidationErrorMessage errorMessage={item.CategoryIds.errorMessage} />
              </div>
            </div>

            <ActiveDatePicker
              key={'active_date_picker'}
              activeState={item.Active.value}
              activeStartDate={item.ActiveStartDate.value}
              activeEndDate={item.ActiveEndDate.value}
              onActiveStateChange={onActiveStateChangeHandler}
              onDateChange={onDateChangeHandler}
            />
          </div>

          <div className="form-group">
            <div className="col-md-offset-2 col-md-10">
              <input type="submit" value="Save" className="btn btn-primary" />
            </div>
          </div>
        </fieldset>
      </form>
    );
  }

  function getUploadFileContent() {
    if (isEdit()) {
      return (
        <div class="form-group">
          <div class="col-sm-2 text-right">
            <img
              class="img-responsive thumb thumb-hover"
              src={getPreviewImage(item.ImageUrl.value, item.LastModified.value)} style={{ filter: 'invert(1)', maxWidth: 100 + 'px' }} />
          </div>
          <div class="col-sm-10">
            <input name="Image" preview="ImageUrl" type="file" accept="image/*"
              onInput={onInputChangeHandler}
              onBlur={onInputChangeHandler}
              onInvalid={onInvalidHandler}
            />
          </div>
        </div>
      );
    }

    return (
      <div class="form-group">
        <label class="col-sm-2 control-label">Upload</label>
        <div class="col-sm-10">
          <input
            required={true}
            name="Image"
            preview="ImageUrl"
            type="file"
            accept="image/*"
            onInput={onInputChangeHandler}
            onBlur={onInputChangeHandler}
            onInvalid={onInvalidHandler}
          />
        </div>
      </div>
    );
  }

  function getUploadFontsFormContent() {
    if (!isEdit()) {
      return null;
    }
    return (
      <form onSubmit={onSubmitFilesForm}>
        <fieldset disabled={isFormDisabled}>
          <div className="form-horizontal">
            <hr />
            <div
              className="alert alert-danger hidden js-fileupload-error" role="alert">
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Uploaded files</label>
              <div className="col-md-10">
                <ul className="js-file-holder">
                  {getUploadedFontFiles()}
                </ul>
              </div>
            </div>
            <div className="form-group">
              <label className="control-label col-md-2">Upload</label>
              <div className="col-md-10">
                <input
                  className="btn btn-default"
                  type="file"
                  name="Logo"
                  accept=".ttf, .wolf"
                  multiple={true}
                  onInput={onFontFileChangeHandler}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="col-md-offset-2 col-md-10">
                <input
                  className="btn btn-default"
                  type="submit"
                  value="Upload"
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    );
  }

  function getUploadedFontFiles() {
    return item.FontFiles.values.map(fontFile => {
      return (
        <li>
          {`${fontFile.FileName} `}
          <a href="/#">
            <span className="glyphicon glyphicon-trash sm-glyph pointer" aria-hidden="true" data-font-file-id={fontFile.FontId} data-font-file-name={fontFile.FileName} onClick={event => deleteFontFile(event, fontFile)}></span>
          </a>
        </li>
      );
    });
  }

  async function onFontFileChangeHandler(event) {
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    setFontFiles(Array.from(event.target.files));
  }

  async function deleteFontFile(event, fontFile) {
    event.preventDefault();
    const filename = fontFile.FileName.value;
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FONT_FILE_FIELD_SINGULAR, fontFile.FileName)) {
      return;
    }

    const result = await FontService.deleteFontFileById(fontFile.Id);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FONT_FILE_FIELD_SINGULAR, filename, result));
      return;
    }
    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FONT_FILE_FIELD_SINGULAR, filename));

    const copy = { ...item };
    copy.FontFiles.values = copy.FontFiles.values.filter(f => f.Id.toString() !== fontFile.Id.toString());
    setItem(copy);
  }

  function getLastModifiedContent() {
    if (!isEdit()) {
      return null;
    }

    return (
      <div className="form-group">
        <label className="control-label col-md-2" for="LastModified">Last Modified</label>
        <div className="col-md-10">
          <p className="form-control-static">{item.LastModified.value} by {item.LastModifiedBy.value}</p>
        </div>
      </div>
    );
  }

  function getProductTypeOptionsContent() {
    return (<TextValuePairOptions items={data.productTypes} selectedValues={item.ProductTypeIds.values} />);
  }

  function getCategoryOptionsContent() {
    return (<TextValuePairOptions items={data.categoryTypes} selectedValues={item.CategoryIds.values} />);
  }

  // Event Handlers
  function onInputChangeHandler(event) {
    const { result, valid } = validateFormWithCustomMessages(item, event);

    if (event.target.type === 'file' && event.target.files && event.target.files.length) {
      const preview = event.target.getAttribute('preview');
      if (preview) {
        // Reset last modified because of the preview image
        result[preview].value = URL.createObjectURL(event.target.files[0]);
      }
    }

    setIsFormValid(valid);
    setItem(result);
  }

  function onSubmitForm(event) {
    event.preventDefault();
    if (!isFormValid) {
      setAlertMessage(AlertMessage.getInvalidFormMessage());
      return;
    }

    if (isEdit()) {
      updateFont();
    } else {
      saveFont();
    }
  }

  async function onSubmitFilesForm(event) {
    event.preventDefault();
    if (!fontFiles.length) {
      return;
    }

    const formData = new FormData();
    fontFiles.forEach(file => formData.append('fileData', file));
    const result = await FontService.uploadFontFiles(fontId, formData);
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemsErrorMessage());
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemsSuccessMessage());

    // Clear form (remove number of uploaded files) and files from the input
    event.target.reset();

    const newFontFiles = fontFiles.map((f, index) => ({ FileName: f.name, FontId: result.data[index] }));
    setIsFormDisabled(false);
    setFontFiles([]);
    const copy = { ...item };
    copy.FontFiles.values = [...copy.FontFiles.values, ...newFontFiles];
    setItem(copy);
  }

  async function updateFont() {
    setIsFormDisabled(true);
    const result = await FontService.update(fontId, getFormData());
    handleUpsertResult(result);
  }

  async function saveFont() {
    setIsFormDisabled(true);
    const result = await FontService.save(getFormData());
    handleUpsertResult(result);
  }

  function getFormData() {
    const formData = new FormData();
    appendIfExists('Active', item.Active.value, formData);
    appendIfExists('ActiveStartDate', item.ActiveStartDate.value, formData);
    appendIfExists('ActiveEndDate', item.ActiveEndDate.value, formData);
    appendIfExists('Title', item.Title.value, formData);
    appendIfExists('MinimumFontSize', item.MinimumFontSize.value, formData);
    appendIfExists('MaximumFontSize', item.MaximumFontSize.value, formData);
    appendIfExists('DefaultFontSize', item.DefaultFontSize.value, formData);
    appendIfExists('CorrectionY', item.CorrectionY.value || '0', formData);
    item.CategoryIds.values.forEach(categoryId => appendIfExists('CategoryIds[]', categoryId, formData));
    item.ProductTypeIds.values.forEach(productTypeId => appendIfExists('ProductTypeIds[]', productTypeId, formData));
    appendIfExists('Image', item.Image.value, formData);
    return formData;
  }

  function handleUpsertResult(result) {
    if (result.error) {
      setIsFormDisabled(false);
      setAlertMessage(AlertMessage.getSaveItemErrorMessage('font', item.Title.value));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage('font', item.Title.value));
    history.push(Routes.font.index);
  }

  function onInvalidHandler(event) {
    // event.preventDefault();
  }

  // Callback Handlers
  function onActiveStateChangeHandler(newState) {
    const copy = { ...item };
    copy.Active.value = newState;
    setItem(copy);
  }

  function onDateChangeHandler(date, field) {
    const copy = { ...item };
    copy[field].value = date;
    setItem(copy);
  }
};
