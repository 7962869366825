import { useEffect, useState } from 'react';
import Spinner from '../../components/common/Spinner';
import { Routes } from '../../components/common/UrlUtils';
import SortableTable from '../../components/SortableTable';
import ServerError from '../../components/states/ServerError';
import { AlertMessage } from '../../utils/AlertMessage';
import EditorOptionsService from './../../services/EditorOptionsService';
import FilterService from './../../services/FilterService';
import { PageUtils } from '../PageUtils';
import { ConfirmationDialog } from '../../components/common/ConfirmationDialog';
import { getLoggedUserName } from '../../utils/authUtils';
import { getPreviewImage } from '../../utils/dataUtils';

export const EditorOptionsPage = () => {
  const PAGE_TITLE = 'Editor Options';
  const FIELD_ID = 'Id';
  const FIELD_SINGULAR = 'filter';
  const FIELD_PLURAL = 'filters';
  const HEADERS = [
    { key: 'ImageUrl', value: 'Thumb', sortable: false },
    { key: 'FilterId', value: 'Filter ID', sortable: true },
    { key: 'HtmlClass', value: 'Html Class', sortable: true },
    { key: 'LastModified', value: 'Last modified', sortable: true },
    { key: 'no_key', value: 'Options', sortable: false }
  ];
  const SEARCH_FIELDS = ['ImageUrl', 'FilterId', 'HtmlClass', 'LastModified'];
  const EDIT_ROUTE = Routes.editorOption.edit;
  const CREATE_ROUTE = Routes.editorOption.create;

  const [isServerError, setIsServerError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState({ by: 'FilterId', order: 'asc' });
  const [alertMessage, setAlertMessage] = useState({
    message: '',
    type: '',
  });
  const [items, setItems] = useState([]);
  const [data, setData] = useState(
    {
      Slider: {
        data: '',
        ui: {
          edit: false,
          create: false,
        },
      },
      Alignment: {
        data: '',
        ui: {
          edit: false,
          create: false,
        }
      },
      Settings: {
        data: '',
        ui: {
          edit: false,
          create: false,
        },
      },
    }
  );

  useEffect(() => {
    if (isLoading) {
      fetchData();
      setIsLoading(false);
    }

    async function fetchData() {
      const [filters, options] = await Promise.all([FilterService.getAll(), EditorOptionsService.getAll()]);
      if (filters.error && options.error) {
        setIsServerError(true);
        return;
      }

      if (!filters.error) {
        setItems(filters.data.map(item => {
          return { ...item, ui: { checked: false } };
        }));
      }

      if (!options.error) {
        const copyData = { ...data };
        copyData.Alignment.data = options.data.find(option => option.OptionType === 'Alignment');
        copyData.Settings.data = options.data.find(option => option.OptionType === 'Settings');
        copyData.Slider.data = options.data.find(option => option.OptionType === 'Slider');
        setData(copyData);
      }
    }
  }, [items, sort, isLoading, isServerError, alertMessage]);

  return (
    <>
      {PageUtils.getPageHeader(PAGE_TITLE, CREATE_ROUTE, FIELD_SINGULAR)}
      {AlertMessage.getContent(alertMessage)}
      {getPageContent()}
    </>
  );

  function getPageContent() {
    if (isLoading) {
      return (<Spinner />);
    }

    if (isServerError) {
      return (<ServerError />);
    }

    return (
      <>
        <div className='table-responsive'>
          <SortableTable
            key={'sortable_table'}
            tableHeader={getHeaderContent()}
            tableFooter={getFooterContent()}
            items={getTableItems()}
            onSearchChanged={onSearchHandler}
            onOrderChange={onOrderChangedHandler}
          />
        </div>
        {getSliderContent()}
        {getAlignmentContent()}
        {getSettingSContent()}
      </>
    );
  }

  // @Override
  function onSearchHandler(searchTerm) {
    setSearchTerm(searchTerm.trim().toLowerCase());
  }

  // @Override
  async function onOrderChangedHandler(initIndex, newIndex, id) {
    const result = await FilterService.updateOrder(id, newIndex);
    const filter = items.find(item => item[FIELD_ID] === id);
    if (result.error) {
      setAlertMessage(AlertMessage.getReorderItemErrorMessage(FIELD_SINGULAR, filter.FilterId, newIndex, result));
      return;
    }

    setAlertMessage(AlertMessage.getReorderItemSuccessMessage(FIELD_SINGULAR, filter.FilterId, newIndex));
  }

  // @Override
  function getHeaderContent() {
    return PageUtils.getSortableHeaderContent(HEADERS, sort, isAllChecked(), checkAllHandler, changeSort);
  }

  // @Override
  function changeSort(event) {
    event.preventDefault();
    const sortBy = event.target.getAttribute('sort-by');
    const order = sortBy === sort.by ? (sort.order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSort({
      by: sortBy,
      order,
    });
  }

  // @Override
  function getFooterContent() {
    return PageUtils.getSortableFooterContent(HEADERS, isAllChecked(), sort, items, FIELD_PLURAL, checkAllHandler, changeSort, onMultiDeleteHandler);
  }

  // @Override
  function isAllChecked() {
    return PageUtils.isAllChecked(items, SEARCH_FIELDS, searchTerm, sort);
  }

  // @Override
  function getTableItems() {
    return PageUtils.getSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort)
      .map(item => {
        return {
          tableData: getItemContent(item),
          active: item.Active,
          key: item[FIELD_ID],
        };
      });
  }

  function getItemContent(item) {
    return (
      <>
        <td key={`${item[FIELD_ID]}_check`}>
          <input
            type='checkbox'
            checked={item.ui.checked}
            onChange={event => onItemSelectChange(item, event)}
          />
        </td>
        <td key={`${item[FIELD_ID]}_thumb`}>
          <img
            className='img-thumbnail thumb'
            src={getPreviewImage(item.ImageUrl, item.LastModified)}
            onError={onImageLoadError} />
        </td>

        <td key={`${item[FIELD_ID]}_filter_id`}>{item.FilterId}</td>
        <td key={`${item[FIELD_ID]}_html_class`}>{item.HtmlClass}</td>
        <td key={`${item[FIELD_ID]}_last_modified`}>{item.LastModified}</td>
        <td key={`${item[FIELD_ID]}_actions`}>
          <a className='btn btn-default btn-xs' href={`${EDIT_ROUTE}/${item[FIELD_ID]}`}>
            <span aria-hidden='true' className='glyphicon glyphicon-pencil'></span> edit
          </a>
          <a className='btn btn-default btn-xs'
            href='#'
            onClick={event => onDeleteItemHandler(item, event)}>
            <span aria-hidden='true' className='glyphicon glyphicon-trash'></span> delete</a>
        </td>
      </>
    );
  }

  // @Override
  function onImageLoadError(event) {
  }

  // @Override
  function checkAllHandler(event) {
    setItems(PageUtils.checkAllSortedMatchedItems(items, SEARCH_FIELDS, searchTerm, sort, event.target.checked));
  }

  // @Override
  async function onDeleteItemHandler(deletedItem, event) {
    event.preventDefault();
    if (!ConfirmationDialog.openDeleteItemConfirmationMessage(FIELD_SINGULAR, deletedItem.FilterId)) {
      return;
    }

    const result = await FilterService.deleteById(deletedItem[FIELD_ID]);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage(FIELD_SINGULAR, deletedItem.FilterId, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage(FIELD_SINGULAR, deletedItem.FilterIdE));
    setItems(items.filter(item => item[FIELD_ID] !== deletedItem[FIELD_ID]));
  }

  // @Override
  async function onMultiDeleteHandler(event) {
    event.preventDefault();

    const deletedItems = items.filter(item => item.ui.checked).map(item => item[FIELD_ID]);
    if (!ConfirmationDialog.openDeleteItemsConfirmationMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL)) {
      return;
    }

    const result = await FilterService.deleteAllById({ FilterIds: deletedItems });
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemsErrorMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemsSuccessMessage(deletedItems.length, FIELD_SINGULAR, FIELD_PLURAL));
    setItems(items.filter(item => !item.ui.checked));
  }

  // @Override
  function onItemSelectChange(item, event) {
    setItems(PageUtils.toggleSelectedItem(items, FIELD_ID, item[FIELD_ID]));
  }

  function getSliderContent() {
    return (
      <ul className="list-group">
        <li className="list-group-item active">
          <div className="row">
            <div className="col-xs-8 col-md-8">Slider</div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className={`btn btn-default btn-xs ${isSliderEmpty() && !data.Slider.ui.edit ? '' : 'hidden'}`}
                  href="/#"
                  onClick={(event) => onAddOptionClick(event, 'Slider')}>
                  <span className="glyphicon glyphicon-plus"></span>add slider option
                </a>
              </div>
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="row">
            <div className="col-xs-8 col-md-8">
              {getSliderInputContent()}
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                {getSliderActionsContent()}
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  }

  function isSliderEmpty() {
    return !data.Slider.data;
  }

  function getSliderInputContent() {
    if (isSliderEmpty() && !data.Slider.ui.edit) {
      return null;
    }

    return (
      data.Slider.ui.edit ?
        <textarea
          id="data-slider-id"
          className="form-control"
          rows="5"
          defaultValue={data.Slider.data ? getPrettyObject(data.Slider.data.OptionValue) : ''}
        >
        </textarea>
        :
        getSnippetPrettyJSON(data.Slider.data.OptionValue)
    );
  }

  function getSliderActionsContent() {
    if (isSliderEmpty() && !data.Slider.ui.edit) {
      return null;
    }

    return (
      data.Slider.ui.edit ?
        getEditActionsContent('Slider')
        :
        getDefaultActionsContent('Slider')
    );
  }

  function getEditActionsContent(fieldName) {
    return (
      <>
        <a
          className="btn btn-primary btn-xs"
          href="/#"
          onClick={(event) => onSaveClick(event, fieldName)}>
          <span
            aria-hidden="true"
            className="glyphicon glyphicon-pencil">
          </span>
          save
        </a>
        <a
          className="btn btn-default btn-xs"
          href="/#"
          onClick={(event) => onCancelEditClick(event, fieldName)}>
          <span
            aria-hidden="true"
            className="glyphicon glyphicon-remove">
          </span>
          cancel
        </a>
      </>
    );
  }

  function getAlignmentContent() {
    return (
      <ul className="list-group">
        <li className="list-group-item active">
          <div className="row">
            <div className="col-xs-8 col-md-8">Align</div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className={`btn btn-default btn-xs ${isAlignmentEmpty() && !data.Alignment.ui.edit ? '' : 'hidden'}`}
                  href="/#"
                  onClick={(event) => onAddOptionClick(event, 'Alignment')}>
                  <span aria-hidden="true" className="glyphicon glyphicon-plus"></span> add align option</a>
              </div>
            </div>
          </div>
        </li>
        <li className="list-group-item">
          {/* <div className="hidden js-hidden-align"></div> */}
          <div className="row">
            <div className="col-xs-8 col-md-8">
              {getAlignmentInputContent()}
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                {getAlignmentActionsContent()}
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  }

  function isAlignmentEmpty() {
    return !data.Alignment.data;
  }

  function getAlignmentInputContent() {
    if (isAlignmentEmpty() && !data.Alignment.ui.edit) {
      return null;
    }

    return (
      data.Alignment.ui.edit ?
        <textarea
          id="data-alignment-id"
          className="form-control"
          rows="5"
          defaultValue={data.Alignment.data ? getPrettyObject(data.Alignment.data.OptionValue, null, 2) : ''}
        >
        </textarea>
        :
        getSnippetPrettyJSON(data.Alignment.data.OptionValue)
    );
  }

  function getAlignmentActionsContent() {
    if (isAlignmentEmpty() && !data.Alignment.ui.edit) {
      return null;
    }

    return (
      data.Alignment.ui.edit ?
        getEditActionsContent('Alignment')
        :
        getDefaultActionsContent('Alignment')
    );
  }

  function getSettingSContent() {
    return (
      <ul className="list-group">
        <li className="list-group-item active">
          <div className="row">
            <div className="col-xs-8 col-md-8">Settings</div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                <a
                  className={`btn btn-default btn-xs ${isSettingsEmpty() && !data.Settings.ui.edit ? '' : 'hidden'}`}
                  href="/#"
                  onClick={(event) => onAddOptionClick(event, 'Settings')}>
                  <span aria-hidden="true"
                    className="glyphicon glyphicon-plus">
                  </span> add settings
                </a>
              </div>
            </div>
          </div>
        </li>
        <li className="list-group-item">
          <div className="hidden js-hidden-settings"></div>
          <div className="row">
            <div className="col-xs-8 col-md-8">
              {getSettingsInputContent()}
            </div>
            <div className="col-xs-4 col-md-4">
              <div className="pull-right">
                {getSettingsActionsContent()}
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  }

  function isSettingsEmpty() {
    return !data.Settings.data;
  }

  function getSettingsInputContent() {
    if (isSettingsEmpty() && !data.Settings.ui.edit) {
      return null;
    }

    return (
      data.Settings.ui.edit ?
        <textarea
          id="data-settings-id"
          className="form-control"
          rows="5"
          defaultValue={data.Settings.data ? getPrettyObject(data.Settings.data.OptionValue) : ''}
        >
        </textarea>
        :
        getSnippetPrettyJSON(data.Settings.data.OptionValue)
    );
  }

  function getSettingsActionsContent() {
    if (isSettingsEmpty() && !data.Settings.ui.edit) {
      return null;
    }

    return (
      data.Settings.ui.edit ?
        getEditActionsContent('Settings')
        :
        getDefaultActionsContent('Settings')
    );
  }

  function getDefaultActionsContent(fieldName) {
    return (
      <>
        <a
          className="btn btn-default btn-xs"
          href="/#"
          onClick={(event) => onEditClick(event, fieldName)}>
          <span
            aria-hidden="true"
            className="glyphicon glyphicon-pencil">
          </span>
          edit
        </a>
        <a
          className="btn btn-default btn-xs"
          href="/#"
          onClick={(event) => onDeleteClick(event, fieldName)}
        >
          <span
            aria-hidden="true"
            className="glyphicon glyphicon-trash">
          </span>
          delete
        </a>
      </>
    );
  }

  function getSnippetPrettyJSON(item) {
    return (<pre className='code-snippet'>{getPrettyObject(item)}</pre>);
  }

  function getPrettyObject(item) {
    if (!item || (Array.isArray(item) && !item.length) || !item.toString().trim()) {
      return '';
    }

    item = item.replaceAll(/\'/g, '"');

    return JSON.stringify(JSON.parse(item), null, 2);
  }

  // Handlers
  function onAddOptionClick(event, optionType) {
    event.preventDefault();
    const copy = { ...data };
    copy[optionType].ui.edit = true;
    copy[optionType].ui.create = true;
    setData(copy);
  }

  async function saveOption(optionValue, optionType) {
    const result = await EditorOptionsService.save({ optionValue, optionType });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage(optionType, '', result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage(optionType, ''));
  }

  function onEditClick(event, fieldName) {
    event.preventDefault();
    const copy = { ...data };
    copy[fieldName].ui.edit = true;
    setData(copy);
  }

  async function onSaveClick(event, optionType) {
    event.preventDefault();

    const field = optionType;
    const text = document.getElementById(`data-${field.toLowerCase()}-id`).value;

    if (!text || !text.trim()) {
      AlertMessage.openConfirmationDialog(`The ${optionType} cannot be empty`);
      return;
    }

    const copy = { ...data };
    try {
      copy[field].data = {
        ...copy[field].data,
        OptionValue: JSON.stringify(JSON.parse(text)),
        OptionType: optionType,
      };
    } catch (error) {
      ConfirmationDialog.openConfirmationDialog('Invalid JSON format');
      return;
    }

    const isCreate = copy[field].ui.create;
    copy[field].ui.edit = false;
    copy[field].ui.create = false;

    isCreate ? createOption(optionType, copy) : updateOption(optionType, copy);
  }

  async function createOption(optionType, copy) {
    const request = {
      OptionType: optionType,
      OptionValue: copy[optionType].data.OptionValue,
      LastModifiedBy: getLoggedUserName(),
    };

    const result = await EditorOptionsService.save(request);
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage('option', optionType, result));
      return;
    }
    setAlertMessage(AlertMessage.getSaveItemSuccessMessage('option', optionType));
    copy[optionType] = {
      ...copy[optionType],
      ...result.data,
    };
    setData(copy);
  }

  async function updateOption(optionType, copy) {
    const result = await EditorOptionsService.updateValue(optionType, { optionValue: copy[optionType].data.OptionValue });
    if (result.error) {
      setAlertMessage(AlertMessage.getSaveItemErrorMessage('option', optionType, result));
      return;
    }

    setAlertMessage(AlertMessage.getSaveItemSuccessMessage('option', optionType));
    copy[optionType] = {
      ...copy[optionType],
      ...result.data,
    };
    setData(copy);
  }

  async function onDeleteClick(event, optionType) {
    event.preventDefault();

    if (!ConfirmationDialog.openDeleteItemConfirmationMessage('option', optionType)) {
      return;
    }

    const result = await EditorOptionsService.deleteByOptionType(optionType);
    if (result.error) {
      setAlertMessage(AlertMessage.getDeleteItemErrorMessage('option', optionType, result));
      return;
    }

    setAlertMessage(AlertMessage.getDeleteItemSuccessMessage('option', optionType));
    const copy = { ...data };
    copy[optionType].data = '';
    setData(copy);
  }

  function onCancelEditClick(event, fieldName) {
    event.preventDefault();
    const copy = { ...data };
    copy[fieldName].ui.edit = false;
    setData(copy);
  }
};
